import React from 'react'
import { useSelector } from 'react-redux'
import { List, Wrap } from 'shared/components/Base/Base'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { SimpleDialog } from 'shared/components/Dialog/SimpleDialog'
import { SimpleDialogWarning } from 'shared/components/Dialog/SimpleDialogWarning'
import { ExternalLink } from 'shared/components/ExternalLink/ExternalLink'
import { WarningIcon } from 'shared/components/Icons/Icon'
import { Text } from 'shared/components/Text/Text'
import { Handler } from 'shared/helpers/typeHelper'
import { useHelpCenterUrl } from 'shared/hooks/useHelpCenterUrl'
import { darkGray, midGray, primaryBlack } from 'shared/theme/colors'

import { useProductSubscription } from '../../../hooks/useProductSubscription'
import { DriverStatus } from '../../../models/models'
import { activeDriversSelector, currentTeamSelector, ratesEmailSelector } from '../../../redux/selectors/team'
import { currentUserSelector } from '../../../redux/selectors/users'
import { EXTERNAL_BOOK_PATH } from '../../../Routes'

interface ExternalBookInstructionsModalProps {
  onClose: Handler
}

export function ExternalBookInstructionsModal({ onClose }: ExternalBookInstructionsModalProps) {
  const { isPremium } = useProductSubscription()
  const user = useSelector(currentUserSelector)
  const team = useSelector(currentTeamSelector)
  const activeDrivers = useSelector(activeDriversSelector)
  const mcNumber = isPremium ? team.ct_mc_number : team.mc_number
  const ratesEmail = useSelector(ratesEmailSelector)
  const isActiveDriver = user.driver?.status === DriverStatus.ACTIVE
  const learnMoreUrl = useHelpCenterUrl('external-load-booking')
  const learnMoreRestrictionsUrl = useHelpCenterUrl('external-load-booking-restrictions')

  function renderInstructionPerProduct() {
    if (isPremium) {
      return (
        <>
          <Text variant="paragraph1" fontColor={midGray} mt={1}>
            If you booked a load outside of CloudTrucks, you can add it here.{' '}
            <ExternalLink variant="paragraph1" href={learnMoreUrl}>
              Learn more
            </ExternalLink>
          </Text>
          <Text variant="paragraph1" fontColor={midGray} mt={1}>
            To process the load as quickly as possible, complete these steps with your broker:
          </Text>

          <Text variant="header3" fontColor={darkGray} mt={3} mb={3} fontWeight={300}>
            <List>
              <li>
                Provide the <strong>driver’s name</strong>
              </li>
              <li>
                Provide the <strong>CloudTrucks MC number: {mcNumber}.</strong>
              </li>
              <li>
                Ask the broker to send the rate confirmation to <strong>{ratesEmail}.</strong>{' '}
              </li>
            </List>
          </Text>

          <SimpleDialogWarning icon={<img src={WarningIcon} alt="icon" />}>
            <Text variant="paragraph2" fontColor={darkGray} mb={1} fontWeight={600}>
              You cannot haul alcohol in some states, hazmat loads, tanks, reefers, containers, and high value
              loads. Team, flatbed and reefer loads are available if you are approved by CloudTrucks Safety.
            </Text>
            <ExternalLink href={learnMoreRestrictionsUrl}>Learn more</ExternalLink>
          </SimpleDialogWarning>
        </>
      )
    }

    return (
      <>
        <Text variant="paragraph1" fontColor={midGray} mt={1}>
          If you booked a load outside of CloudTrucks, you can add it here.
          <ExternalLink variant="paragraph1" href={learnMoreUrl}>
            Learn more
          </ExternalLink>
        </Text>
        <Text variant="paragraph1" fontColor={midGray} mt={1}>
          To process the load as quickly as possible, ask your broker to send the rate confirmation to:{' '}
          <strong>{ratesEmail}</strong>
        </Text>
        <Text variant="paragraph1" fontColor={midGray} mt={1}>
          By booking, you agree to move the load.
        </Text>
      </>
    )
  }

  function renderDialogBody() {
    if (!user.is_team_admin && !isActiveDriver) {
      return (
        <>
          <Text variant="header2" fontColor={primaryBlack}>
            Complete your onboarding to add external loads.
          </Text>
          <Text variant="paragraph1" fontColor={midGray} mt={2} mb={4}>
            You can add external loads once you complete your onboarding. To check your progress, go to your
            profile
          </Text>
          <Button variant="primary" size="large" margin="0 auto" onClick={onClose}>
            OK
          </Button>
        </>
      )
    }

    if (activeDrivers.length === 0) {
      return (
        <>
          <Text variant="header2" fontColor={primaryBlack}>
            Your drivers are still onboarding.
          </Text>
          <Text variant="paragraph1" fontColor={midGray} mt={2} mb={4}>
            You can add external loads once your drivers complete their onboarding. To check their progress,
            go to their profiles
          </Text>
          <Button variant="primary" size="large" margin="0 auto" onClick={onClose}>
            OK
          </Button>
        </>
      )
    }

    return (
      <>
        <Text variant="header2" fontColor={primaryBlack}>
          Add an externally booked load
        </Text>
        {renderInstructionPerProduct()}
        <Box mt={4} />
        <Button variant="primary" size="large" margin="0 auto" href={EXTERNAL_BOOK_PATH}>
          Continue
        </Button>
        <Box mt={3} />
        <Button variant="text" margin="0 auto" onClick={onClose}>
          Cancel
        </Button>
      </>
    )
  }

  return (
    <SimpleDialog onClose={onClose} isOpen alignText="left" xsFullScreen>
      <Wrap maxWidth="390px">{renderDialogBody()}</Wrap>
    </SimpleDialog>
  )
}
